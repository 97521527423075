import React from "react"
import { graphql } from "gatsby"
import { Element } from "react-scroll"
import { Helmet } from "react-helmet"
import loadable from "@loadable/component"
import Layout from "@src/layout"

import ScrollerButton from "@src/components/button/scroller"

import AdventurousLife from "@src/sections/adventurous-life"

const EmbarkAndLead = loadable(() =>
  import(/* webpackPrefetch: true */ "@src/sections/embark-and-lead")
)
const BlazingNewTrails = loadable(() =>
  import(/* webpackPrefetch: true */ "@src/sections/blazing-new-trails")
)
const LearnFromTrial = loadable(() =>
  import(/* webpackPrefetch: true */ "@src/sections/learn-from-trial")
)
const CountTheCost = loadable(() =>
  import(/* webpackPrefetch: true */ "@src/sections/count-the-cost")
)
const EyesOnTheSummit = loadable(() =>
  import(/* webpackPrefetch: true */ "@src/sections/eyes-on-the-summit")
)

const Page = ({ data }) => (
  <Layout>
    <Helmet
      title={data.site.siteMetadata.title}
      meta={[
        {
          name: `description`,
          content: data.site.siteMetadata.description
        },
        {
          property: `og:title`,
          content: data.site.siteMetadata.title
        },
        {
          property: `og:image`,
          content: data.site.siteMetadata.image
        },
        {
          property: `og:description`,
          content: data.site.siteMetadata.description
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: data.site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: data.site.siteMetadata.title
        },
        {
          name: `twitter:image`,
          content: data.site.siteMetadata.image
        },
        {
          name: `twitter:description`,
          content: data.site.siteMetadata.description
        }
      ]}
    ></Helmet>
    <Element name="adventurous-life" id="adventurous-life">
      <AdventurousLife />
    </Element>

    <Element name="embark-and-lead" id="embark-and-lead">
      <EmbarkAndLead />
    </Element>

    <Element name="blazing-new-trails" id="blazing-new-trails">
      <BlazingNewTrails />
    </Element>

    <Element name="learn-from-trial" id="learn-from-trial">
      <LearnFromTrial />
    </Element>

    <Element name="count-the-cost" id="count-the-cost">
      <CountTheCost />
    </Element>

    <Element name="eyes-on-the-summit" id="eyes-on-the-summit">
      <EyesOnTheSummit />
    </Element>

    <ScrollerButton />
  </Layout>
)

export const IndexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        image
      }
    }
  }
`

Page.propTypes = {}

Page.defaultProps = {}

export default Page
